import React from 'react';
import styled from 'react-emotion';
import withProps from 'recompose/withProps';
import { colors } from '../../utils/styles';

const ResponsiveTable = styled('div')`
  display: block;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-x: auto;
  width: 100%;
`;

const Table = styled('table')`
  border-collapse: collapse;
  max-width: 100%;
  min-width: 600px;
  width: 100%;
`;

const ThLeft = styled('th')`
  padding: 4px 8px 4px 0;
  text-align: left;
`;

const ThBrand = styled('th')`
  background: ${colors.brand};
  border-left: 1px solid #9d7cbf;
  color: ${colors.lightest};
  -webkit-font-smoothing: antialiased;
  padding: 8px 0;
`;

const Tr = styled('tr')`
  border-bottom: ${props => (props.last ? 0 : '1px solid #e0d6eb')};
`;

const Td = styled('td')`
  border-left: 1px solid #f5f3f7;
  padding: 8px 4px;
  text-align: center;
  vertical-align: top;
`;

const TdLeft = withProps({
  colSpan: '2'
})(styled('td')`
  padding: 4px 8px 4px 0;
`);

const TShirtSizeChartTable = ({ unit }) => {
  const multiplier = unit === 'in' ? 0.39 : 1;
  const Size = ({ children: value }) => (
    <span>{Math.round(value * multiplier * 10) / 10}</span>
  );

  return (
    <ResponsiveTable>
      <Table>
        <tbody>
          <tr>
            <ThLeft>Style</ThLeft>
            <ThBrand>Sizes</ThBrand>
            <ThBrand>S</ThBrand>
            <ThBrand>M</ThBrand>
            <ThBrand>L</ThBrand>
            <ThBrand>XL</ThBrand>

          </tr>
          <Tr>
            <TdLeft>Unisex Body Height</TdLeft>
            <Td>
              <Size>66</Size>–<Size>67</Size>
            </Td>
            <Td>
              <Size>68</Size>–<Size>69</Size>
            </Td>
            <Td>
              <Size>71</Size>–<Size>72</Size>
            </Td>
            <Td>
              <Size>73</Size>–<Size>74</Size>
            </Td>
          </Tr>
          <Tr last>
            <TdLeft>Unisex Chest</TdLeft>
            <Td>
              <Size>47</Size>–<Size>48.5</Size>
            </Td>
            <Td>
              <Size>49.5</Size>–<Size>51</Size>
            </Td>
            <Td>
              <Size>52.5</Size>–<Size>54</Size>
            </Td>
            <Td>
              <Size>55</Size>–<Size>56</Size>
            </Td>
          </Tr>
        </tbody>
      </Table>
    </ResponsiveTable>
  );
};

export default TShirtSizeChartTable;
